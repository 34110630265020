import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../../components/LoadingComponent';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, AppBar, Toolbar, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Dialog, Slide, Radio, Divider, Button, Skeleton, Fab, InputAdornment, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Badge } from '@mui/material';
import { Add, AddOutlined, ArrowBackOutlined, Check, CheckBox, CloseOutlined, EditOutlined, EventAvailableOutlined, Facebook, Favorite, FavoriteBorderOutlined, Instagram, LinkOutlined, LocalOfferOutlined, PrintOutlined, QueryBuilderOutlined, RemoveOutlined, SearchOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp, WifiProtectedSetupOutlined } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { createNewTheme, toReal, traduzirMes } from '../../functions/utils';

import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import '../../components/NotificacaoCard.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PedidosMesa({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemsNovos, setItemsNovos] = useState();
  const [formaPagamento, setFormaPagamento] = useState("");
  const [salvarTaxas, setSalvarTaxas] = useState(false);
  const [items, setItems] = useState();
  const [tipoModal, setTipoModal] = useState("alterarMesa");
  const [isPizza, setIsPizza] = useState(false);
  const [segundoSabor, setSegundoSabor] = useState(false);
  const [tamanhoPizza, setTamanhoPizza] = useState("");
  const [pedidoSelecionado, setPedidoSelecionado] = useState("");
  const [pedidoCancelarSelecionado, setPedidoCancelarSelecionado] = useState("");
  const [pedidosRealizados, setPedidosRealizados] = useState("");
  const [openModalItem, setOpenModalItem] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [editando, setEditando] = useState(false);
  const [editandoNome, setEditandoNome] = useState(false);
  const [novoNumeroMesa, setNovoNumeroMesa] = useState("");
  const [novoNomeMesa, setNovoNomeMesa] = useState("");
  const [pessoas, setPessoas] = useState("");
  const [tipoTaxa, setTipoTaxa] = useState("p");
  const [tipoDesconto, setTipoDesconto] = useState("p");
  const [taxa, setTaxas] = useState("");
  const [desconto, setDesconto] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [valorTotal, setValorTotal] = useState("");
  const [valorPorPessoas, setValorPorPessoas] = useState("");
  const [valorDescontos, setValorDescontos] = useState("");
  const [valorTaxas, setValorTaxas] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [itemSelected, setItemSelected] = useState([]);
  const [opcionaisSelected, setOpcionaisSelected] = useState({});
  const [adicionaisSelected, setAdicionaisSelected] = useState({});
  const [limiteOpcionaisSelected, setLimiteOpcionaisSelected] = useState({});
  const [carrinho, setCarrinho] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [destaques, setDestaque] = useState(null);
  const [urlBase, setUrlBase] = useState("");
  const [nome, setNome] = useState("");
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [valorTotalItem, setValorTotalItem] = useState(0);
  const [valorMinimo, setValorMinimo] = useState(false);
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [favoritos, setFavoritos] = useState(null);
  const [enableForBuy, setEnabledForBuy] = useState(false);
  const [textColor, setTextColor] = useState('#FFF');

  const navigate = useNavigate();

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleCloseItem = () => {
    getPedidos();
    setOpenModalItem(false);
  };

  const handleCloseConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNovoPedido = (event, numero) => {
    if (numero > 0) {
      Cookies.set('nM', numero, { expires: 7 });
    } else { Cookies.remove("nM"); }
    onChange("", "cardapio-venda");
    Cookies.set('tE', 2, { expires: 7 });
    Cookies.set('bCkPg', "mesa", { expires: 7 });
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    getPedidos();
  }, [update]);

  const getFormasPagamento = () => {
    return Cookies.get("fMsP").split("--");
  };

  const getPedidos = () => {
    const idestabelecimento = Cookies.get("Md");
    const urlbase = Cookies.get("UBs");
    setUrlBase(urlbase);
    axios.post(`${urlbase}/pedidos-s.php`, {
      idestabelecimentos: idestabelecimento,
      isretirada: false,
      ismesa: true
    })
      .then(response => {
        response = response.data
        let lastMesa, mesas = [], count = 0, countN = 0

        if (!response) {
          onChange("updateNot", "")
          setItems([])
          setItemsNovos([])
          handleCloseLoading();
          return
        }
        for (let user of response) {
          const arrMesas = user.nomecliente.split("--");
          const numeroMesa = parseInt(arrMesas[0]);
          const nomeCliente = arrMesas[2].charAt(0).toUpperCase() + arrMesas[2].slice(1).toLowerCase();

          if (lastMesa != numeroMesa) {
            lastMesa = numeroMesa;
            user.numeroMesa = numeroMesa
            user.nomeClienteMesa = nomeCliente
            if (user.status !== "E") {
              user.statusNotificacao = true
              countN += 1
            } else {
              user.statusNotificacao = false
            }
            mesas.push(user)
            count++
          } else {
            if (mesas[count - 1]) {
              if (!mesas[count - 1].statusNotificacao && user.status !== "E") {
                mesas[count - 1].statusNotificacao = true
                countN += 1
              }
            }
          }
        }

        countN > 0 ? Cookies.set('nTMs', true, { expires: 7 }) : Cookies.remove('nTMs')
        onChange("updateNot", "")
        setItems(mesas)
        setItemsNovos(mesas)
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };

  const handleCancelarPedidoMesa = () => {

    const post_url = urlBase + "/deletar-pedido.php";
    const json = JSON.stringify({
      idpedidos: pedidoCancelarSelecionado.idpedidos,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel cancelar o pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            setOpenModalConfirm(false);
            setOpenModalItem(false);
            handleCloseItem();
            handleSnack("Pedido cancelado com sucesso.");
          } else {
            handleSnack("Não foi possivel cancelar o seu pedido, tente novamente.");
          }
        }
        getPedidos();
      })
  }

  const handleConfirmCancelar = (event, pedido) => {
    event.stopPropagation();
    setPedidoCancelarSelecionado(pedido)
    setOpenModalConfirm(true);
    setTipoModal("cancelarPedidoMesa");
  }

  const handleConfirmAtualizarPedido = (event, pedido, status) => {
    event.stopPropagation();
    setPedidoSelecionado(pedido)
    setOpenModalConfirm(true);
    setTipoModal("finalizarPedido");
  }

  const handleFinalizarMesa = () => {
    let status = "F", arrHora
    const timestampAtual = Math.floor(new Date().getTime() / 1000);
    let demaishoras = pedidoSelecionado.demaishoras
    demaishoras ? arrHora = demaishoras.split(",") : arrHora = (",,").split(",");
    switch (status) {
      case "P":
        arrHora[0] = timestampAtual;
        break;
      case "E":
        arrHora[1] = timestampAtual;
        break;
      case "F":
        arrHora[2] = timestampAtual;
        break;
    }
    demaishoras = (`${arrHora[0]},${arrHora[1]},${arrHora[2]}`).replaceAll("undefined", "")

    const post_url = urlBase + "/editar-pedido.php";
    const json = JSON.stringify({
      idpedidos: pedidoSelecionado.nomecliente,
      status: "F",
      demaishoras: demaishoras
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            setOpenModalConfirm(false);
            setOpenModalItem(false);
            handleSnack("Comanda finalizada com sucesso.");
          } else {
            handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
          }
        }
        getPedidos();
      })
  }

  const handlePesquisar = (event) => {
    setItemsNovos(items.filter(item => item.data.toLowerCase().includes(event.target.value.toLowerCase()) || item.nomecliente.toLowerCase().includes(event.target.value.toLowerCase()) || item.endereco.toLowerCase().includes(event.target.value.toLowerCase())))
    setPesquisar(event.target.value);
  };

  const handlePrint = () => {
    window.electronListener.imprimirPedidoManual(urlBase, pedidoSelecionado.nomecliente.includes("--") ? pedidoSelecionado.nomecliente : pedidoSelecionado.idpedidos, Cookies.get("tmFi"));
  };

  const handleItem = (produto) => {
    const post_url = urlBase + "/pedido.php?idpedidos=" + produto.nomecliente;

    axios.post(post_url)
      .then(resp => {
        const response = resp.data
        setPedidoSelecionado(produto)
        console.log(response[0].formapagamento.toLowerCase())
        setFormaPagamento(response[0].formapagamento.toLowerCase())
        let valorTotal = 0
        for (const dados of response) {
          valorTotal += parseFloat(dados.valor.replaceAll(".", "").replaceAll(",", "."))
        }
        setSubTotal(toReal(valorTotal))
        let tservico, tdesconto, vservico, vdesconto
        if (response[0].servico) {
          const arr = response[0].servico.split("--")
          tservico = arr[0]
          vservico = arr[1]
        } else {
          tservico = "p"
          vservico = "0"
        }
        if (response[0].desconto) {
          const arr = response[0].desconto.split("--")
          tdesconto = arr[0]
          vdesconto = arr[1]
        } else {
          tdesconto = "p"
          vdesconto = "0"
        }
        setSalvarTaxas(false);
        setTipoTaxa(tservico)
        setTipoDesconto(tdesconto)
        setTaxas(vservico)
        setDesconto(vdesconto)
        updateTaxaDesconto(tservico, tdesconto, vservico, vdesconto, toReal(valorTotal))
        setPedidosRealizados(response)
      })
    setOpenModalItem(true)
  };

  const handleSalvarTaxas = () => {
    const opcaoTaxa = tipoTaxa
    const opcaoDesconto = tipoDesconto
    opcaoTaxa == undefined ? opcaoTaxa = "p" : console.log("-- DEBUG --")
    opcaoDesconto == undefined ? opcaoDesconto = "p" : console.log("-- DEBUG --")

    let valorTaxa = parseFloat(taxa.replaceAll(",", "."))
    let valorDesconto = parseFloat(desconto.replaceAll(",", "."))
    isNaN(valorTaxa) ? valorTaxa = 0 : console.log("-- DEBUG --")
    isNaN(valorDesconto) ? valorDesconto = 0 : console.log("-- DEBUG --")

    const post_url = urlBase + "/editar-valores-pedido.php";
    const json = JSON.stringify({
      idpedidos: pedidoSelecionado.nomecliente,
      servico: `${opcaoTaxa}--${valorTaxa}`,
      desconto: `${opcaoDesconto}--${valorDesconto}`,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            handleSnack("Taxas atualizadas com sucesso.");
            setSalvarTaxas(false);
          } else {
            handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel conectar ao servidor, tente novamente mais tarde.");
      });
  }

  const getPedidosRealizados = (detalhes, produto) => {
    const detalhesArray = JSON.parse(atob(detalhes));
    let typographies = [], count = 1
    for (const detalhe of detalhesArray) {
      typographies.push(
        <Typography style={{ padding: "0px 20px 10px 20px", marginTop: "-8px", alignItems: "center", fontSize: "16px" }}>
          {count === 1 && (
            <>
              <b style={{ color: "#7588a3", fontSize: 18 }}>PEDIDO #{produto.data}<br /></b>
            </>
          )}
          {detalhe.quantidade}x {detalhe.nome}
          <strong style={{ float: "right" }}><Switch label="label" checked={produto.status === "E"} onChange={() => handleEntregarPedido(produto.data, produto.status)} style={{ marginLeft: "auto" }} /></strong><br />
          <strong style={{ color: "#94a3b8", fontWeight: "normal", fontSize: "14px" }}>Observações: {detalhe.observacoes ? detalhe.observacoes : "Não há observações"}</strong><br />
          {getAdicionais(detalhe.adicionais)}
          <strong style={{ color: "#333", fontSize: "16px" }}>
            Subtotal: R$ {detalhe.valorTotal}
          </strong>
          {count === detalhesArray.length && (
            <>
              <strong style={{ float: "right", cursor: "pointer", background: "#FF5733", color: "white", borderRadius: "5px", padding: 3, marginTop: -10, fontSize: 14 }} onClick={(event) => handleConfirmCancelar(event, produto)}><CloseOutlined label="label" style={{ marginBottom: -7, fontSize: 22 }} /> &nbsp;Cancelar</strong>
              <div style={{ height: 10 }}></div>
              <Divider />
            </>
          )}
          <div style={{ height: "10px" }}></div>
        </Typography>
      );
      count += 1
    }

    return typographies;
  };

  const getAdicionais = (adicionais) => {
    return adicionais.map((detalhe, index) => (
      <>
        <strong key={index} style={{ color: "#94a3b8", fontSize: "14px" }}>
          + {detalhe.nome ? detalhe.nome : detalhe.nomeOpcional} R$ {detalhe.valor ? detalhe.valor : detalhe.valorOpcional}
        </strong><br />
      </>
    ));
  };

  const handleEntregarPedido = (id, status) => {

    status === "E" ? status = "P" : status = "E"
    const post_url = urlBase + "/atualizar-pedido.php";
    const request_method = "post";
    const json = JSON.stringify({
      idpedidos: id,
      status: status
    });

    axios.post(post_url, json)
      .then(resp => {
        handleItem(pedidoSelecionado)
        handleSnack("Seu pedido foi atualizado com sucesso.");
      })


  };

  const handleFormaPagamento = (event) => {
    setFormaPagamento(event.target.value);

    const post_url = urlBase + "/editar-pagamento-pedido.php";
    const json = JSON.stringify({
      idpedidos: pedidoSelecionado.nomecliente,
      formapagamento: event.target.value.toUpperCase(),
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            handleSnack("Seu pedido foi atualizado com sucesso.");
          } else {
            handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel conectar ao servidor, tente novamente mais tarde.");
      });
  };

  const handleEditClick = () => {
    setEditando(true);
  };

  const handleEditNomeClick = () => {
    setEditandoNome(true);
  };

  const handleInputChange = (event) => {
    setNovoNumeroMesa(event.target.value);
  };

  const handleInputNomeChange = (event) => {
    setNovoNomeMesa(event.target.value);
  };

  const handleDividir = (event) => {
    let t
    updateTaxaDesconto(tipoTaxa, tipoDesconto, taxa, desconto, t, event.target.value)
    setPessoas(event.target.value);
  };

  const handleTaxas = (event) => {
    setSalvarTaxas(true);
    updateTaxaDesconto(tipoTaxa, tipoDesconto, event.target.value, desconto)
    setTaxas(event.target.value);
  };

  const handleDesconto = (event) => {
    setSalvarTaxas(true);
    updateTaxaDesconto(tipoTaxa, tipoDesconto, taxa, event.target.value)
    setDesconto(event.target.value);
  };

  const handleBlur = () => {
    setEditando(false);
    if (!/^[0-9]+$/.test(novoNumeroMesa)) {
      handleSnack("O numero da mesa não pode incluir letras ou caracteres especiais.");
      return
    }
    if (novoNumeroMesa == 0) {
      handleSnack("O numero da mesa deve ser maior que zero.");
      return
    }
    if (novoNumeroMesa == pedidoSelecionado.numeroMesa) {
      return
    }
    setTipoModal("alterarMesa")
    setOpenModalConfirm(true)
  };

  const handleBlurNome = () => {
    setEditandoNome(false);
    if (!novoNomeMesa) {
      handleSnack("O nome não pode ser vazio.");
      return
    }
    if (novoNomeMesa.toLowerCase() == pedidoSelecionado.nomeClienteMesa.toLowerCase()) {
      return
    }
    handleAlterarNomeMesa()
  };

  const handleAlterarNomeMesa = () => {
    const arrNm = pedidoSelecionado.nomecliente.split("--")

    console.log(pedidoSelecionado.nomecliente)
    console.log(pedidoSelecionado.nomeClienteMesa)
    console.log(novoNomeMesa)
    console.log(`${arrNm[0]}--${arrNm[1]}--${novoNomeMesa}`)
    const post_url = urlBase + "/editar-mesa.php";
    const json = JSON.stringify({
      nomemesa: pedidoSelecionado.nomecliente,
      nomemesanovo: `${arrNm[0]}--${arrNm[1]}--${novoNomeMesa}`,
    });
    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            pedidoSelecionado.nomeClienteMesa = novoNomeMesa
            pedidoSelecionado.nomecliente = `${arrNm[0]}--${arrNm[1]}--${novoNomeMesa}`
            handleSnack("Nome do cliente atualizado com sucesso");
          } else {
            handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel conectar ao servidor, tente novamente mais tarde.");
      });
  };

  const handleAlterarMesa = () => {
    const arrNm = pedidoSelecionado.nomecliente.split("--")

    const post_url = urlBase + "/editar-mesa.php";
    const json = JSON.stringify({
      nomemesa: pedidoSelecionado.nomecliente,
      nomemesanovo: `${adicionarZeroEsquerda(novoNumeroMesa)}--${arrNm[1]}--${arrNm[2]}`,
    });
    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
        }
        else {
          if (response.resposta === "sucesso") {
            pedidoSelecionado.numeroMesa = novoNumeroMesa
            pedidoSelecionado.nomecliente = `${adicionarZeroEsquerda(novoNumeroMesa)}--${arrNm[1]}--${arrNm[2]}`
            handleSnack("Número da mesa atualizado com sucesso");
          } else {
            handleSnack("Não foi possivel atualizar o seu pedido, tente novamente.");
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel conectar ao servidor, tente novamente mais tarde.");
      });
    setOpenModalConfirm(false)
  };

  const adicionarZeroEsquerda = (numero) => {
    if (numero < 10) {
      return `0${numero}`;
    } else {
      return numero.toString();
    }
  }

  const handleTipoTaxa = (valor) => {
    setSalvarTaxas(true);
    updateTaxaDesconto(valor, tipoDesconto, taxa, desconto)
    setTipoTaxa(valor);
  };

  const handleTipoDesconto = (valor) => {
    setSalvarTaxas(true);
    updateTaxaDesconto(tipoTaxa, valor, taxa, desconto)
    setTipoDesconto(valor);
  };

  const updateTaxaDesconto = (tipot, tipod, valort, valord, sbTotal, p) => {
    if (!sbTotal) {
      sbTotal = subTotal
    }
    if (p === "") { p = 1 }
    if (!p) { p = pessoas }
    setValorPorPessoas()
    let novoValorTotal = calculaValorTotalPedido(sbTotal, tipot, tipod, valort, valord);
    if (p) {
      if (/^[0-9]+$/.test(p)) {
        p = parseInt(p)
        if (p > 0) {
          setValorPorPessoas(toReal(parseFloat(novoValorTotal.replaceAll(".", "").replaceAll(",", ".")) / p))
        }
      }
    }
    setValorTotal(novoValorTotal)
  }

  const calculaValorTotalPedido = (valorTotal, tipoTaxa, tipoDesconto, taxa, desconto) => {
    const opcaoTaxa = tipoTaxa
    const opcaoDesconto = tipoDesconto

    let valorTaxa = parseFloat(taxa.replaceAll(",", "."))
    let valorDesconto = parseFloat(desconto.replaceAll(",", "."))
    isNaN(valorTaxa) ? valorTaxa = 0 : console.log("-- DEBUG --")
    isNaN(valorDesconto) ? valorDesconto = 0 : console.log("-- DEBUG --")
    if (opcaoTaxa == "r") {
      setValorTaxas(valorTaxa !== 0 ? toReal(valorTaxa) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(",", ".")) + valorTaxa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorTaxas(valorTaxa !== 0 ? toReal(parseFloat(valorTotal.replaceAll(",", ".")) * (valorTaxa / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(",", ".")) * (1 + (valorTaxa / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    if (opcaoDesconto == "r") {
      setValorDescontos(valorDesconto !== 0 ? toReal(valorDesconto) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(",", ".")) - valorDesconto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
      setValorDescontos(valorDesconto !== 0 ? toReal(parseFloat(valorTotal.replaceAll(",", ".")) * (valorDesconto / 100)) : "0,00")
      valorTotal = (parseFloat(valorTotal.replaceAll(",", ".")) * (1 - (valorDesconto / 100))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return valorTotal
  }

  const getDatahora = (data) => {
    if (data) {
      let [dia, mes, a, restante] = data.split(" ");
      mes = traduzirMes(mes);
      return `${dia} ${mes} - ${restante}`;
    }
    return ""
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleClose}
              message={msgSnack}
              style={{ zIndex: "10001" }}
            />

            <Dialog
              open={openModalItem}
              onClose={handleCloseItem}
              TransitionComponent={Transition}
              style={{ background: "rgba(0,0,0,0.7)" }}
            >
              <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: 20, display: "flex", alignItems: "center", fontSize: 22 }}>
                {editando ? (
                  <strong style={{ color: "#333", cursor: "pointer" }} onClick={handleEditClick}>
                    MESA <input
                      type="text"
                      value={novoNumeroMesa}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      autoFocus
                      style={{ fontSize: "22px", width: "50px" }}
                    />
                  </strong>
                ) : (
                  <strong style={{ color: "#333", cursor: "pointer" }} onClick={handleEditClick}>
                    MESA {pedidoSelecionado.numeroMesa} &nbsp;&nbsp;<EditOutlined style={{ marginBottom: "-4px", background: `rgba(${colorPrimary})`, color: "white", borderRadius: "5px", padding: 2 }} />
                  </strong>
                )}
                <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(pedidoSelecionado.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px", marginRight: 10 }} />
                <CloseOutlined variant='outlined' style={{ float: "right", width: 30, cursor: "pointer" }} onClick={() => handleCloseItem()} />
              </Typography>
              <Divider />
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                <strong style={{ color: "#333" }}>Dados do cliente</strong>
              </Typography>
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px", marginTop: "-18px" }}>
                {editandoNome ? (
                  <strong style={{ color: "#333", cursor: "pointer" }} onClick={handleEditNomeClick}>
                    <input
                      type="text"
                      value={novoNomeMesa}
                      onChange={handleInputNomeChange}
                      onBlur={handleBlurNome}
                      autoFocus
                      style={{ fontSize: "22px", width: "300px" }}
                    />
                  </strong>
                ) : (
                  <strong style={{ color: "#333", fontWeight: "normal" }} onClick={handleEditNomeClick}>
                    {pedidoSelecionado.nomeClienteMesa}  &nbsp;&nbsp;<EditOutlined style={{ marginBottom: "-4px", background: `rgba(${colorPrimary})`, color: "white", borderRadius: "5px", padding: 2 }} />
                  </strong>
                )}
              </Typography>
              <Divider style={{ marginLeft: 10, marginRight: 10 }} />
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                <strong style={{ color: "#333" }}>Detalhes do pedido</strong>
                <strong style={{ color: "#333", marginLeft: "auto" }}>Entregue</strong>
              </Typography>
              {pedidosRealizados ? (
                <>
                  {pedidosRealizados.map((produto) => (
                    <>
                      {getPedidosRealizados(produto.detalhes, produto)}
                    </>
                  ))}
                </>
              ) : (
                <>
                </>
              )}
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                <strong style={{ color: "#333" }}>Detalhes do pagamento</strong>
              </Typography>
              <div style={{ height: "10px" }}></div>
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px", marginTop: "-18px" }}>
                <FormControl fullWidth>
                  <InputLabel>Forma de pagamento</InputLabel>
                  <Select
                    label="Forma de pagamento"
                    value={formaPagamento}
                    onChange={handleFormaPagamento}
                  >
                    <MenuItem key={"débito"} value={"débito"}>
                      DÉBITO
                    </MenuItem>
                    <MenuItem key={"crédito"} value={"crédito"}>
                      CRÉDITO
                    </MenuItem>
                    <MenuItem key={"vr/va"} value={"vr/va"}>
                      VR/VA
                    </MenuItem>
                    {getFormasPagamento().map((forma, index) => {
                      if (forma.includes(",")) {
                        return forma.split(",").map((tipo, index) => (
                          <MenuItem key={index} value={tipo.trim()}>
                            {tipo.toUpperCase().trim()}
                          </MenuItem>
                        ));
                      } else if (forma.includes("(") || forma.includes("/") || forma.includes("-")) {
                        return (
                          <MenuItem key={index} value={"pix"}>
                            PIX
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem key={index} value={forma.trim()}>
                            {forma.toUpperCase().trim()}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Typography>

              <Divider style={{ marginLeft: 10, marginRight: 10, marginTop: "10px" }} />

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                <strong style={{ color: "#333" }}>Taxa de serviço e desconto</strong>
              </Typography>

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-25px" }}>
                <Typography style={{ flex: 1, fontSize: "17px", paddingRight: 10 }}>
                  <FormControlLabel
                    checked={tipoTaxa === "p"}
                    onChange={() => handleTipoTaxa("p")}
                    control={<Radio />}
                    label={<span style={{ fontSize: '12px' }}>Taxa (%)</span>}
                  />
                  <FormControlLabel
                    checked={tipoTaxa === "r"}
                    onChange={() => handleTipoTaxa("r")}
                    control={<Radio />}
                    label={<span style={{ fontSize: '12px' }}>Taxa (R$)</span>}
                  />
                  <TextField id="outlined-basic" fullWidth label="Taxa de serviço" variant="outlined" style={{ width: "100%" }}
                    value={taxa}
                    onChange={handleTaxas} />
                </Typography>
                <Typography style={{ flex: 1, fontSize: "17px" }}>
                  <FormControlLabel
                    checked={tipoDesconto === "p"}
                    onChange={() => handleTipoDesconto("p")}
                    control={<Radio />}
                    label={<span style={{ fontSize: '12px' }}>Desconto (%)</span>}
                    style={{ marginLeft: "5px" }}
                  />
                  <FormControlLabel
                    checked={tipoDesconto === "r"}
                    onChange={() => handleTipoDesconto("r")}
                    control={<Radio />}
                    label={<span style={{ fontSize: '12px' }}>Desconto (R$)</span>}
                  />
                  <TextField id="outlined-basic" label="Desconto" variant="outlined" style={{ width: "100%" }}
                    value={desconto}
                    onChange={handleDesconto} />
                </Typography>
              </Typography>

              {salvarTaxas && (
                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                  <Button disableElevation variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={handleSalvarTaxas}>Salvar taxas</Button>
                </Typography>
              )}

              <Divider style={{ marginLeft: 10, marginRight: 10, marginTop: "10px" }} />

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                <strong style={{ color: "#333" }}>Dividir valores (recurso apenas visual)</strong>
              </Typography>

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-10px" }}>
                <TextField id="outlined-basic" label="Quantidade de pessoas" variant="outlined" style={{ width: "100%" }}
                  value={pessoas}
                  onChange={handleDividir} />
              </Typography>

              <Divider style={{ marginLeft: 10, marginRight: 10, marginTop: "10px" }} />

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                <strong style={{ color: "#333" }}>Subtotal</strong>
                <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {subTotal}</strong>
              </Typography>

              {pedidoSelecionado.taxa && parseFloat(pedidoSelecionado.taxa.replaceAll(",", ".")) && (
                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                  <strong style={{ color: "#333" }}>Taxa de entrega</strong>
                  <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {pedidoSelecionado.taxa}</strong>
                </Typography>
              )}

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                <strong style={{ color: "#333" }}>Taxa de serviço</strong>
                <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTaxas}</strong>
              </Typography>
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "-15px" }}>
                <strong style={{ color: "#333" }}>Desconto</strong>
                <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorDescontos}</strong>
              </Typography>
              {pessoas > 0 && (
                <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-15px" }}>
                  <strong style={{ color: "#ea580c" }}>Valor por pessoa</strong>
                  <strong style={{ color: "#ea580c", marginLeft: "auto" }}>R$ {valorPorPessoas}</strong>
                </Typography>
              )}
              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "17px", marginTop: "-15px" }}>
                <strong style={{ color: "#333" }}>Valor total</strong>
                <strong style={{ color: "#333", marginLeft: "auto" }}>R$ {valorTotal}</strong>
              </Typography>

              <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                <Button disableElevation startIcon={<PrintOutlined />} variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={handlePrint}>Imprimir</Button>
                <Button disableElevation startIcon={<Check />} variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleConfirmAtualizarPedido(event, pedidoSelecionado, "P")}>Finalizar comanda</Button>
              </Typography>
              <div style={{ height: "20px" }}></div>
            </Dialog>

            <Dialog
              open={openModalConfirm}
              onClose={handleCloseConfirm}
              TransitionComponent={Transition}
              style={{ background: "rgba(0,0,0,0.7)" }}
            >
              {tipoModal === "alterarMesa" && (
                <>
                  <Typography style={{ padding: "30px 30px 20px 30px", display: "flex", alignItems: "center", fontSize: "24px" }}>
                    <strong style={{ color: "#333" }}>Confirmar alteração na mesa.</strong>
                  </Typography>
                  <Typography style={{ padding: "0px 30px 0px 30px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Ao confirmar esta ação, os pedidos serão vinculados permanentemente e não será possível desfazer essa operação. Deseja continuar?</strong>
                  </Typography>
                  <Typography style={{ padding: 30, display: "flex", alignItems: "center", fontSize: "16px" }}>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={() => setOpenModalConfirm(false)}>Voltar</Button>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginLeft: "10px" }} onClick={() => handleAlterarMesa()}>Confirmar</Button>
                  </Typography>
                </>
              )}

              {tipoModal === "finalizarPedido" && (
                <>
                  <Typography style={{ padding: "30px 30px 20px 30px", display: "flex", alignItems: "center", fontSize: "24px" }}>
                    <strong style={{ color: "#333" }}>Finalizar comanda da mesa.</strong>
                  </Typography>
                  <Typography style={{ padding: "0px 30px 0px 30px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Deseja finalizar a comanda da mesa {pedidoSelecionado.numeroMesa}?</strong>
                  </Typography>
                  <Typography style={{ padding: 30, display: "flex", alignItems: "center", fontSize: "16px" }}>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={() => setOpenModalConfirm(false)}>Voltar</Button>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginLeft: "10px" }} onClick={() => handleFinalizarMesa()}>Confirmar</Button>
                  </Typography>
                </>
              )}

              {tipoModal === "cancelarPedidoMesa" && (
                <>
                  <Typography style={{ padding: "30px 30px 20px 30px", display: "flex", alignItems: "center", fontSize: "24px" }}>
                    <strong style={{ color: "#333" }}>Cancelar pedido da mesa.</strong>
                  </Typography>
                  <Typography style={{ padding: "0px 30px 0px 30px", display: "flex", alignItems: "center", fontSize: "17px" }}>
                    <strong style={{ color: "#333" }}>Essa é uma ação irreversível, deseja realmente cancelar o pedido #{pedidoCancelarSelecionado.data}?</strong>
                  </Typography>
                  <Typography style={{ padding: 30, display: "flex", alignItems: "center", fontSize: "16px" }}>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#FF5733", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginRight: "10px" }} onClick={() => setOpenModalConfirm(false)}>Voltar</Button>
                    <Button disableElevation variant="contained" style={{ backgroundColor: "#4CAF50", width: "100%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize", marginLeft: "10px" }} onClick={() => handleCancelarPedidoMesa()}>Confirmar</Button>
                  </Typography>
                </>
              )}

            </Dialog>

            <Typography style={{ fontWeight: "bold", paddingTop: 11 }}>Meus pedidos para mesa</Typography>

            <div style={{ height: "20px" }}></div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                )
              }} variant="outlined" fullWidth
                value={pesquisar}
                placeholder="Buscar por (Número do Pedido, Nome ou Endereço)"
                onChange={handlePesquisar} />
              <div style={{ width: "30px" }}></div>
              <Button startIcon={<AddOutlined />} variant="contained" style={{ fontWeight: "bold", textTransform: "capitalize", color: "#fff", width: 200, height: 56 }} onClick={handleNovoPedido}>Novo pedido</Button>
            </div>

            <div style={{ display: 'flex', position: "absolute", left: 0, right: 0, bottom: 0, top: 150 }}>
              <div style={{ flex: 1, backgroundColor: '#84cc16', marginRight: 10, height: '100vh', border: "solid white 16px", borderRightWidth: "8px", overflowY: "auto" }}>
                <Typography style={{ color: "white", padding: "10px", fontSize: "18px", fontWeight: "bold" }}>
                  Comandas em aberto
                </Typography>
                <div style={{ height: "20px" }}></div>

                <Grid container spacing={2}>
                  {itemsNovos ? (
                    <>
                      {itemsNovos.map((produto) => (
                        <Grid item xs={12} sm={4}>
                          <Card className={produto.statusNotificacao ? 'piscar' : ''} style={{ width: "95%", marginLeft: "2.5%", cursor: "pointer" }} onClick={() => handleItem(produto)}>
                            <Typography style={{ position: "relative", top: "0px", background: "#f1f5f9", padding: "10px 20px 10px 20px", display: "flex", alignItems: "center" }}>
                              <strong style={{ color: "#333" }}>MESA {produto.numeroMesa}</strong>
                              {produto.statusNotificacao ? (
                                <Badge variant='dot'
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }} badgeContent="" color="primary" style={{ marginLeft: "auto" }}>
                                  <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                                </Badge>
                              ) : (
                                <Chip variant='outlined' icon={<EventAvailableOutlined />} label={getDatahora(produto.datahora)} style={{ marginLeft: "auto", paddingLeft: "10px", paddingRight: "10px" }} />
                              )}
                            </Typography>
                            <Divider />
                            <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                              <strong style={{ color: "#333" }}>Dados do cliente</strong>
                              <strong style={{ color: "#333", marginLeft: "auto" }}>Telefone</strong>
                            </Typography>
                            <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                              <strong style={{ color: "#333", fontWeight: "normal" }}>{produto.nomeClienteMesa}</strong>
                              <strong style={{ color: "#333", fontWeight: "normal", marginLeft: "auto" }}>{produto.telefonecliente ? produto.telefonecliente : "Não informado"}</strong>
                            </Typography>
                            <Typography style={{ padding: "10px 20px 10px 20px", display: "flex", alignItems: "center", fontSize: "16px" }}>
                              <Button disableElevation startIcon={<Add />} variant="contained" style={{ width: "90%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleNovoPedido(event, produto.numeroMesa)}>Novo Pedido</Button>
                              <Button disableElevation startIcon={<Check />} variant="contained" style={{ backgroundColor: "#4CAF50", width: "90%", marginLeft: "2.5%", height: "40px", color: "white", fontWeight: "bold", textTransform: "capitalize" }} onClick={(event) => handleConfirmAtualizarPedido(event, produto, "P")}>Finalizar comanda</Button>
                            </Typography>
                          </Card>
                          <div style={{ height: "12px" }}></div>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </div>

            <SwipeableDrawer
              container={container}

              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableSwipeToOpen={true}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

              teste


            </SwipeableDrawer>

            <div style={{ height: "150px" }}></div>

          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default PedidosMesa;

