import { Container, AppBar, Toolbar, Typography, IconButton, Button, Snackbar } from '@mui/material';
import { AddOutlined, ArrowBackOutlined, DeleteOutline, RemoveOutlined } from '@mui/icons-material';
import { useLocation, useNavigate, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme } from '../functions/utils';

import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Cookies from 'js-cookie';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Carrinho() {

  const state = useLocation().state;
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [carrinho, setCarrinho] = useState([]);
  const [urlBase, setUrlBase] = useState("");
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: page } })
    } else {
      navigate("/", { replace: true, state: { page: page } })
    }
  };
  
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      handleBack();
    };

    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleClear = () => {
    Cookies.remove('crU');
    Cookies.remove('bCrt');
    Cookies.remove('vPt');
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      navigate(`/?nm=${btoa(numeroMesa)}`, { replace: true, state: { page: page } })
    } else {
      navigate("/", { replace: true, state: { page: page } })
    }
  };

  const handleEnderecos = () => {
    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      Cookies.set('tE', 1, { expires: 7 });
      Cookies.set('fmP', "mesa", { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    } else {
      navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
  };

  const handleAddQtd = (id) => {
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      return;
    }

    for (const produto of carrinho) {
      if (produto.id === id) {
        const newValue = produto.quantidade + 1
        if (produto.limiteestoque && newValue > parseInt(produto.limiteestoque)) {
          handleSnack(`Só existe apenas ${produto.limiteestoque} em estoque.`)
          return
        }
        produto.quantidade += 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  const handleRmQtd = (id) => {
    let count = 0
    for (const produto of carrinho) {
      if (produto.id === id) {
        if (produto.quantidade === 1) {
          carrinho.splice(count, 1);
          continue
        }
        produto.quantidade -= 1
        produto.valorTotal = toReal(parseFloat(produto.valorComAdicionais.replaceAll(".", "").replaceAll(",", '.')) * produto.quantidade)
      }
      count++
    }
    let bloquearCarrinho = Cookies.get('bCrt');
    if (bloquearCarrinho) {
      handleClear();
    }
    Cookies.set('crU', btoa(JSON.stringify(carrinho)), { expires: 7 });
    updateCart();
  };

  useEffect(() => {

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    const url = Cookies.get('UBs');
    setUrlBase(url);
    updateCart();

  }, []);

  const updateCart = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho && Cookies.get("vPt")) {
        Cookies.remove('crU');
        handleBack()
      }
      Cookies.set('vlT', toReal(valorTotal), { expires: 7 });
      setValorTotalCarrinho(toReal(valorTotal))
    }
    if (currentCarrinho && currentCarrinho.length === 0) {
      Cookies.remove('crU');
      handleBack()
    }
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Sacola
            <Button style={{ marginLeft: "auto", textTransform: "none", fontWeight: "700", color: "white" }} onClick={handleClear}>Limpar</Button>
          </Toolbar>
        </AppBar>

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
          style={{ zIndex: "10001" }}
        />

        <div style={{ height: "80px" }}></div>

        <Button variant="text" style={{ textTransform: "none", fontWeight: "700" }} startIcon={<AddOutlined />} onClick={handleBack}>
          Adicionar mais itens
        </Button>

        <div style={{ height: "8px" }}></div>

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {carrinho.map((produto) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <img src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.id}_0.png?${new Date().getTime()}`} style={{ borderRadius: "4px", height: "56px", width: "56px" }} onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                  }} />
                </ListItemAvatar>
                <ListItemText
                  primary={produto.nome}
                  primaryTypographyProps={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold", paddingLeft: "16px" }}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "16px" }}>
                        {produto.descricao}
                        {(produto.adicionais).map((adicional) => (
                          <>
                            <b style={{ fontSize: "12px" }}><br />+ {adicional.quantidadeOpcional}x {adicional.nomeOpcional}</b>
                          </>
                        ))}
                      </Typography>
                    </React.Fragment>
                  }
                />

              </ListItem>
              <div style={{ backgroundColor: "white", padding: "16px", display: "flex", alignItems: "center" }}>
                <Typography style={{ float: "left", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
                  {produto.valorTotal === "0,00" && Cookies.get("vPt") ? (
                    <>
                      Resgate
                    </>
                  ) : (
                    <>
                      {produto.valorTotal === "0,00" || produto.valorTotal == 0 ?
                        <>A Consultar</>
                        :
                        <> R$ {produto.valorTotal} </>
                      }
                    </>
                  )}
                </Typography>

                <div style={{ position: "absolute", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", height: "40px", display: "flex", alignItems: "center" }}>
                  <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{produto.quantidade}</p>
                  {produto.quantidade === 1 ? (
                    <DeleteOutline style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                  ) : (
                    <RemoveOutlined style={{ position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleRmQtd(produto.id)} />
                  )}
                  <AddOutlined style={{ position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleAddQtd(produto.id)} />
                </div>
              </div>
            </>
          ))}
        </List>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Typography style={{ float: "left", fontSize: "14px", fontWeight: "bold", paddingLeft: "16px" }}>
            Total
          </Typography>
          <Typography style={{ float: "right", fontSize: "16px", fontWeight: "bold", color: `rgba(${colorPrimary})` }}>
            {valorTotalCarrinho === "0" && Cookies.get("vPt") ? (
              <>
                Resgate
              </>
            ) : (
              <>
                {valorTotalCarrinho === "0,00" || valorTotalCarrinho == 0 ?
                  <>A Consultar</>
                  :
                  <> R$ {valorTotalCarrinho} </>
                }
              </>
            )}
          </Typography>
          <div style={{ height: "35px" }}></div>
          <Button fullWidth variant="contained" size="large" style={{ textTransform: "none", fontWeight: "700" }} onClick={handleEnderecos}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "200px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Carrinho;